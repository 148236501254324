.templateWrapper {
  background-color: #4A38D6;

  .content {
    display: flex;
    padding: 40px 4vw;

    .rightSection, .leftSection {
      max-width: 50%;
    }

    .rightSection {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media (max-width: 720px) {
        max-width: 100%;
      }

      h1.mainHeader {
        color: white;
        margin-top: 20px;
        line-height: 75px;
        text-transform: uppercase;
        font: normal normal 500 69px/91px Oswald;
        letter-spacing: 2px;

        @media (max-width: 500px) {
          font-size: 40px;
          line-height: 45px;
        }
      }

      p.description {
        font-size: 1.3em;
        font: normal normal normal 19px/33px Open Sans;
        max-width: 80%;
        color: white;
      }

      .logoWrapper {
        img {
          max-width: 120px;
        }
      }
    }

    .leftSection {
      .fingerprintWrapper {
        margin-top: 30vh;

        @media (max-width: 900px) {
          position: fixed;
          margin-top: 0;
          margin-left: 10%;
          top: -5px;
        }

        @media (max-width: 720px) {
          display: none;
        }

        img {
          max-width: 105%;

          @media (max-width: 900px) {
            max-width: 100%;
          }
        }
      }
    }
  }

  .footer {
    color: white;
    max-width: 90%;
    margin: 0 auto;
    border-top: 1px solid lightgray;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }
}