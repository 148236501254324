.text-center {
  background-color: #0a1e65;
  color: white;
  cursor: pointer;
  border: 1px dashed lightgray;
  margin-bottom: 40px;

  .uploadedFiles {
    margin-left: 10px;
    margin-top: 10px;

    .uploadedFile {
      width: 100%;
      display: flex;
      align-items: baseline;

      span {
        margin-right: 5px;
        margin-left: 3px;
      }
    }
  }

  .content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .dropzone-content {
      .chooseFilesBtn {
        font: normal normal bold 31px/42px Oswald;
        color: white;
        box-shadow: 0px 2px 2px #00000061;
        border-radius: 5px;
        width: 215px;
        height: 59px;
        background-color: #4A38D6;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        margin: 0;
        margin-top: 10px;
        min-width: 100%;
      }
    }
  }
}

