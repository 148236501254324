.loader-block {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(241, 242, 252, .4);
  z-index: 1000;
  width: 100%;
}

#loader-4 span{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: red;
  margin: 35px 5px;
  opacity: 0;
}

#loader-4 span:nth-child(1){
  animation: opacitychange 1s ease-in-out infinite;
}

#loader-4 span:nth-child(2){
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}

#loader-4 span:nth-child(3){
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange{
  0%, 100%{
    opacity: 0;
  }

  60%{
    opacity: 1;
  }
}
