$inputWidth: 45%;
$inputWidthMd: 90%;
$inputRightMargin: 20px;

.mainFormWrapper {

  .infoFields {
    background-color: #0a1e65;
    border: 1px dashed lightgray;
    height: auto;
    min-width: 300px;
    padding: 5% 10%;

    .infoFieldItems {
      margin: 0 auto;
      margin-top: 30px;
      max-width: calc(#{$inputWidth} * 2 + #{$inputRightMargin});

      @media (max-width: 1050px) {
        text-align: left;
      }


      input {
        min-width: $inputWidth;
        margin-bottom: 20px;
        padding: 7px;
        border: none;
        border-radius: 4px;

        @media (max-width: 1050px) {
          min-width: $inputWidthMd;
        }

        &:nth-child(2n) {
          margin-right: calc(#{$inputRightMargin + 6px + 4px});

          @media (max-width: 1050px) {
            margin-right: 0;
          }
        }
      }

      .required {
        color: red;
        margin-right: $inputRightMargin;
        padding-bottom: 5px;
        min-width: 6px;
        padding-left: 4px;
      }
    }

    .formControls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .termsAgreement {

        span {
          text-transform: uppercase;
          color: white;
        }

        span.terms {
          cursor: pointer;
          color: #5555ff;
        }
      }

      .submitBtnWrapper {

        button {
          color: white;
          width: 120px;
          height: 60px;
          background-color: #4A38D6;
          border-radius: 5px;
          border: none;
          cursor: pointer;

          text-transform: uppercase;
          font: normal normal bold 31px/42px Oswald;

          &[disabled] {
            cursor: auto;
            background-color: lightgray;

            &:hover {
              background-color: lightgray;
            }
          }

          &:hover {
            background-color: #6052de;
          }
        }
      }
    }
  }
}